<template>
    <div>
        {{path}}
    </div>
</template>

<script>
import { Toast } from 'vant';
import axios from 'axios'
export default {
    name:"index",
    data(){
        return{
           path:''
        }
    },
    methods: {
        getList(){
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.path = window.location.href
            var href = window.location.href
            var newHerf = href.slice(39)
            Toast.clear();
            newHerf = decodeURIComponent(newHerf)
            window.location.href = newHerf
        },
    },
    mounted() {
        this.getList()
    },
    components:{
        [Toast.name]: Toast,
    }
}
</script>